import React, { useEffect } from 'react'
import Layout from '../components/layout/layout'
import { Box, Container, Heading, Text } from '@chakra-ui/react'
import useScript from '../hooks/useScript'
import Bugsnag from '@bugsnag/js'
import { withErrorBoundary } from '../components/functionality/errorReporting'

const SocialErrorScreen = () => (
  <Box height={'300px'} bgColor={'tan.50'}>
    <Text textAlign={'center'} fontSize={'lg'}>
      There was an error loading the content
    </Text>
  </Box>
)

const SocialFeed = withErrorBoundary({ FallbackComponent: SocialErrorScreen })(
  ({ id, section }) => {
    const status = useScript('https://fl-1.cdn.flockler.com/embed/embed-v2.js')

    useEffect(() => {
      if (status !== 'ready') return

      window.flEmbed = window.flEmbed || {}
      if (window.flcklr && window.flcklr.Embeds && !window.flEmbed[id]) {
        window.flEmbed[id] = window.flcklr.Embeds.create({
          // These values, including section, can be taken from the flockler embed
          // script
          container: id,
          section: [section],
          site: 7799,
          style: 'wall_v2',
          count: 15,
          defaultCss: true,
          defaultJs: true,
          extra: {
            wall_timestamps: true,
            wall_timestamps_format: 'relative',
            popup: false,
          },
          infiniteScroll: 1,
          refresh: 0,
          showLikes: 1,
          showComments: 1,
          showRetweets: 1,
          showProfile: true,
        })
      }

      return () => {
        try {
          window.flEmbed[id] && window.flEmbed[id].destroy()
          delete window.flEmbed[id]
        } catch (e) {
          console.error(e)
          Bugsnag.notify(e)
        }
      }
    }, [status, id, section])

    return (
      <Box
        id={id}
        px={2}
        sx={{
          '.flockler-wall_v2-item__text__content': {
            color: '#1a202c',
          },
          '.flockler-wall_v2-item__link': {
            fontWeight: 'bold',
            color: '#847244 !important',
          },
        }}
      />
    )
  }
)

export default function MediaPage() {
  return (
    <Layout
      meta={{
        title: 'Karma: Featured Press & Media',
        description:
          "Karma: A Yogi's Guide to Crafting Your Destiny by Sadhguru by Sadhguru, New York Times bestselling author featured in media.",
      }}
    >
      <Box bgColor={'tan.50'} py={12}>
        <Container maxWidth={'container.xl'}>
          <Heading color={'tan.heading'} variant={'h1'} mb={12}>
            Featured Press & Media
          </Heading>
        </Container>
        <SocialFeed
          id={'flockler-embed-179243bac3907bb80c1253424c7cf54e'}
          section={50927}
        />

        <Container maxWidth={'container.xl'}>
          <Heading color={'tan.heading'} variant={'h1'} mb={12}>
            Social Media
          </Heading>
        </Container>
        <SocialFeed
          id={'flockler-embed-179424922d30e8a43f1ca616996aef8b'}
          section={51100}
        />
      </Box>
    </Layout>
  )
}
